import React from "react"

import SvgIconCheck from "../images/svg/check-icon.svg"
import SvgIconClose from "../images/svg/close-icon.svg"

const ListItem = ({
  children = "Pellentesque erat id amet vel proin aliquam diam. Et non quis tellus elementum nulla quam curabitur. client-focused results.",
  className = "",
  iconClassName = "",
  isChecked = true,
}) => {
  return (
    <>
      <div
        className={`flex items-center justify-start gap-3 item3 text-body-sm tablet:text-body-md text-neutral-500 ${className}`}
      >
        <div
          className={`flex items-center justify-center flex-shrink-0 w-6 h-6 rounded-full shape bg-success-100 ${iconClassName}`}
        >
          {isChecked ? <SvgIconCheck /> : <SvgIconClose />}
        </div>
        <div>{children}</div>
      </div>
    </>
  )
}
export default ListItem
