import React from "react"

import Section from "./Section"
import Container from "./Container"
import SectionTitle from "./SectionTitle"
import SectionText from "./SectionText"

const InnerHero = ({
  title = "FAQ 1",
  preTitle = "",
  text = "",
  children,
  className = "",
}) => {
  return (
    <>
      <Section isHero className="overflow-hidden bg-neutral-100">
        <Container>
          {/* wrap */}
          <div className="relative">
            {preTitle && (
              <p className="relative z-30 pb-3 font-medium text-center text-body-md tablet:text-body-lg text-primary-600">
                {preTitle}
              </p>
            )}

            {/* texts */}
            <div
              className={`flex flex-col items-center justify-center relative z-30 ${className}`}
            >
              <SectionTitle>{title}</SectionTitle>
              {text && (
                <SectionText className="max-w-[500px] mx-auto text-center !pb-0">
                  {text}
                </SectionText>
              )}

              {children}
            </div>

            <div className="h-[100px] w-[100px] bg-primary-100 absolute laptop:left-0 left-0 -translate-x-[95%] -translate-y-[95%] top-0 laptop:top-1/2 laptop:-translate-y-1/2">
              <div className="absolute z-10 w-10 h-10 bg-secondary-200 left-full top-full"></div>
            </div>

            <div className="h-[100px] w-[100px] z-20 bg-success-100 absolute laptop:right-0 right-0 top-0 translate-x-[95%] translate-y-[95%] laptop:top-1/2 laptop:-translate-y-1/2">
              <div className="absolute w-10 h-10 bg-secondary-200 right-full top-full"></div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default InnerHero
