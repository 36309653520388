import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Pricing1 from "../sections/pricings/Pricing1"
import Testimonial1 from "../sections/testimonials/Testimonial1"

const PricingPage2 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasTop: true,
          hasBottomSocial: true,
          bottomClassName: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - Pricing 02`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="Pricing 02"
          text="Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line."
        />
        <Pricing1 />
        <Testimonial1 />
      </PageWrapper>
    </>
  )
}

export default PricingPage2
