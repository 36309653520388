import React from "react"
import { Link } from "gatsby"

import SectionText from "../SectionText"
import TextSmall from "../TextSmall"
import Button from "../Button"

const PricingCard1 = ({
  preTitle = "Free",
  title = "$0 per month",
  text = "Try Kloft free for your business with basic features. Upgrade anytime.",
  className = "",
  buttonClassName = "",
  isPopular = false,
  children,
}) => {
  return (
    <>
      <div data-aos="fade-up">
        <div
          className={`relative bg-white flex flex-col border-2 mx-auto max-w-[400px] border-primary-400 gap-14 rounded-3xl px-9 py-11 ${className}`}
        >
          {/* top */}
          <div className="flex flex-col gap-6">
            <SectionText
              isHero="false"
              className={"!pb-0 font-medium text-neutral-600"}
            >
              {preTitle}
            </SectionText>
            <div className="flex flex-col gap-4">
              <h6 className={"font-bold text-neutral-700 text-display-sm"}>
                {title}
              </h6>
              <TextSmall>{text}</TextSmall>
            </div>
          </div>
          {/* middle */}
          <div className="flex flex-col gap-4">{children}</div>
          {/* bottom */}
          <div className="">
            <Link to="#">
              <Button
                size={"lg"}
                className={`border border-primary-50 bg-primary-50 text-neutral-800 ${buttonClassName}`}
              >
                Get started
              </Button>
            </Link>
          </div>
          {/* popular */}
          {isPopular && (
            <div className="absolute top-0 pt-3 -translate-x-1/2 -translate-y-[55%] -z-10 border rounded-lg left-1/2 pb-11 border-neutral-200 px-14">
              <TextSmall className="!font-medium text-primary-600">
                Popular
              </TextSmall>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default PricingCard1
