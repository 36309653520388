import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

import Section from "../../components/Section"
import Button from "../../components/Button"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import TestimonialCard1 from "../../components/cards/TestimonialCard1"

const Testimonial1 = ({ className }) => {
  const [sliderRef, instanceRef] = useKeenSlider({
    mode: "free-snap",
    loop: true,
    initial: 1,
    slides: {
      origin: "center",
      perView: 1,
      spacing: 20,
    },

    breakpoints: {
      "(min-width: 768px)": {
        initial: 2,
        slides: {
          origin: "center",
          perView: 2,
          spacing: 32,
        },
      },
      "(min-width: 992px)": {
        initial: 3,
        slides: {
          origin: "center",
          perView: 3,
          spacing: 56,
        },
      },
    },
  })

  const { image1, image2, image3, image4, image5, image6 } =
    useStaticQuery(graphql`
      query {
        image1: allFile(
          filter: { relativePath: { eq: "avatar-profile-photo-1.png" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image2: allFile(
          filter: { relativePath: { eq: "avatar-profile-photo-2.png" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image3: allFile(
          filter: { relativePath: { eq: "avatar-profile-photo-3.png" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image4: allFile(
          filter: { relativePath: { eq: "avatar-profile-photo-4.png" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image5: allFile(
          filter: { relativePath: { eq: "avatar-profile-photo-5.png" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        image6: allFile(
          filter: { relativePath: { eq: "avatar-profile-photo-6.png" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    `)

  return (
    <>
      <Section className={`bg-neutral-50 ${className}`}>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col justify-center items-center gap-8 tablet:gap-14 laptop:gap-[82px]">
            {/* top-part */}
            <div data-aos="fade-in" className="">
              <SectionTitle className="text-center">
                {" "}
                Our customers{" "}
                <span className="text text-primary-600">love</span>
                {""} us
              </SectionTitle>
            </div>

            {/* testimonial cards */}
            <div ref={sliderRef} className="keen-slider">
              {/* card */}
              <TestimonialCard1
                imageData={image1.nodes[0]}
                personName="Rose T. Parman"
                personInfo="Designer"
              >
                Taking seamless key performance indicators offline to maximise
                the long tail. Keeping your eye on the ball while performing a
                deep dive on the start-up.
              </TestimonialCard1>
              {/* card */}
              <TestimonialCard1
                imageData={image2.nodes[0]}
                personName="Debbie R. Biehl"
                personInfo="Programer"
              >
                Completely synergize resource taxing relationships via premier
                niche markets. Professionally cultivate one-to-one customer
                service with robust ideas.
              </TestimonialCard1>
              {/* card */}
              <TestimonialCard1
                imageData={image3.nodes[0]}
                personName="Wayne C. Corey"
                personInfo="Desinger"
              >
                Capitalize on low hanging fruit to identify a ballpark value
                added activity to beta test. Override the digital divide with
                additional clickthroughs from DevOps.
              </TestimonialCard1>
              <TestimonialCard1
                imageData={image4.nodes[0]}
                personName="John C. Valenza"
                personInfo="Manager"
              >
                Objectively innovate empowered manufactured products whereas
                parallel platforms. Holisticly predominate extensible testing.
              </TestimonialCard1>
              <TestimonialCard1
                imageData={image5.nodes[0]}
                personName="Wayne C. Corey"
                personInfo="Programer"
              >
                Override the digital divide with additional clickthroughs from
                DevOps. Nanotechnology immersion along the information highway
              </TestimonialCard1>
              <TestimonialCard1
                imageData={image6.nodes[0]}
                personName="Wayne C. Corey"
                personInfo="Desinger"
              >
                Bring to the table win-win survival strategies to ensure
                proactive domination. At the end of the day, going forward, a
                new normal that has evolved from.
              </TestimonialCard1>
            </div>

            {/* bottom-part */}
            <div className="flex justify-center gap-4">
              <Button
                className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10 !px-0 !py-0"
                iconOnly={"false"}
                icon={<ChevronLeft className="font-bold text-body-xl" />}
                onClick={e =>
                  e.stopPropagation() || instanceRef.current?.prev()
                }
              ></Button>
              <Button
                className="text-black hover:text-neutral-50 bg-white !border border-neutral-300 h-10 w-10"
                iconOnly={"false"}
                icon={<ChevronRight className="font-bold text-body-xl" />}
                onClick={e =>
                  e.stopPropagation() || instanceRef.current?.next()
                }
              ></Button>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Testimonial1
