import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Quote } from "react-bootstrap-icons"

import SectionText from "../SectionText"

const TestimonialCard1 = ({
  children = "Completely synergize resource taxing relationships via premierniche markets. Professionally cultivate one-to-one customerservice with robust ideas.",
  icon = <Quote />,
  iconClassName = "",
  className = "",
  personName = "Debbie R. Biehl",
  personInfo = "Programer",
  imageData,
}) => {
  const image = getImage(imageData)

  return (
    <>
      <div
        className={`keen-slider__slide  pl-4 flex flex-col gap-6 justify-start items-start relative ${className}`}
      >
        <div
          className={`absolute bottom-6 right-2 -z-10  text-primary-100 text-display-md  ${iconClassName}`}
        >
          {icon}
        </div>
        {/* top */}
        <div className="">
          <SectionText className="!text-neutral-600 !pb-0">
            {children}
          </SectionText>
        </div>
        {/* bottom */}
        <div className="flex items-center gap-4 ">
          <GatsbyImage
            image={image}
            alt={personName}
            className="border-4 rounded-full border-white h-[72px] w-[72px] object-cover  overflow-hidden"
          />
          <div className="flex flex-col gap-0">
            <p className="font-medium text-body-lg text-neutral-700">
              {personName}
            </p>
            <p className="font-normal text-body-md text-neutral-500">
              {personInfo}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
export default TestimonialCard1
