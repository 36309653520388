import React from "react"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import PricingCard1 from "../../components/cards/PricingCard1"
import ListItem from "../../components/ListItem"

const Pricing1 = () => {
  return (
    <>
      <Section>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-14 justify-center items-center tablet:gap-[100px]">
            <div data-aos="fade-in">
              <SectionTitle>
                {" "}
                Flexible{" "}
                <span className="text-center text text-primary-600">
                  pricing
                </span>{" "}
                that scales
              </SectionTitle>
            </div>
            {/* cards */}
            <div className="grid grid-cols-1 gap-16 laptop:gap-8 tablet:grid-cols-2 laptop:grid-cols-3">
              {/* card */}
              <PricingCard1
                preTitle="Free"
                title="$0 per month"
                text="Try Kloft free for your business with basic features. Upgrade anytime."
                className="!border !border-neutral-200"
                buttonClassName="hover:!text-neutral-200"
              >
                <ListItem>Dashboard Access</ListItem>
                <ListItem>Limited 5k Viewers</ListItem>
                <ListItem>Integrations with APIs</ListItem>
                <ListItem iconClassName="!bg-neutral-100" isChecked={false}>
                  5,000+ Members
                </ListItem>
                <ListItem iconClassName="!bg-neutral-100" isChecked={false}>
                  Whitelabelling
                </ListItem>
              </PricingCard1>
              <PricingCard1
                isPopular={true}
                preTitle="Professional"
                title="$25 per month"
                text="Try Kloft free for 7 days and get unrestricted access to all our products."
                buttonClassName="!bg-primary-600 !text-white"
              >
                <ListItem>Dashboard Access</ListItem>
                <ListItem>Limited 5k Viewers</ListItem>
                <ListItem>Integrations with APIs</ListItem>
                <ListItem>5,000+ Members</ListItem>
                <ListItem iconClassName="!bg-neutral-100" isChecked={false}>
                  Whitelabelling
                </ListItem>
              </PricingCard1>

              <PricingCard1
                preTitle="Enterprise"
                title="$59 per month"
                text="Try Kloft free for 30 days and get unrestricted access to all our products."
                className="!border border-neutral-200 tablet:translate-x-1/2 laptop:translate-x-0 tablet:left-8 laptop:left-0 relative"
                buttonClassName="hover:!text-neutral-200"
              >
                <ListItem>Dashboard Access</ListItem>
                <ListItem>Limited 5k Viewers</ListItem>
                <ListItem>Integrations with APIs</ListItem>
                <ListItem>5,000+ Members</ListItem>
                <ListItem>Whitelabelling</ListItem>
              </PricingCard1>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Pricing1
